

const config = {
    'base_URL_Auth' : 'https://rentspothub.com/auth-service',
    'base_URL_User' : 'https://rentspothub.com/user-service',
    'base_URL_House' : 'https://rentspothub.com/house-service',
    'base_URL_Collect' : 'https://rentspothub.com/collect-service',
    'base_URL_Company' : 'https://rentspothub.com/company-service',
    'base_URL_Announcement' : 'https://rentspothub.com/system-service',
    'contactPhone' : '0912951085',
    'contactMail' : 'acr.webhouse@gmail.com',
    'GoogleMapKey' : 'AIzaSyB7bluQMbii0q2B5v7o6SABJRgddKW8GYE',
    'mainPage' : 'https://rentspothub.com',
}

     
export {config}


