const errorCode = {
    isOk : 0,
    accountMailOrPasswordInvalid : 1,
    userNotVerify : 2,
    accountIsExist : 3,
    mailIsExist : 4,
    userIsNotHouseOwner :5,
    userIsNotAccountOwner : 6,
    dbError : 7,
    internetError : 8,
    unKnowError : 9,
    houseAddressIsExist :10,
    tokenCreateFail :11,
    refreshTokenInvalid : 12,
    accessTokenInvalid : 13,
}

export {errorCode}