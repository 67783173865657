import React from 'react';
import HouseUpload from "../Components/HouseUpload";

const AddHouseData = () => (
    <div className="App">
        <HouseUpload/>
    </div>
);


export default AddHouseData;