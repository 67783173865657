// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/antd/dist/antd.min.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/antd-button-color/dist/css/style.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ant-checkbox-checked .ant-checkbox-input{\n    width: 30px;\n    height: 30px;\n}\n\n.ant-checkbox-input\n{\n    /* Checkbox text */\n    font-size: 150%;\n    display: inline;\n}", "",{"version":3,"sources":["webpack://./src/Components/Register_form.css"],"names":[],"mappings":"AAGA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;;IAEI,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB","sourcesContent":["@import 'antd/dist/antd.min.css';\n@import 'antd-button-color/dist/css/style.css';\n\n.ant-checkbox-checked .ant-checkbox-input{\n    width: 30px;\n    height: 30px;\n}\n\n.ant-checkbox-input\n{\n    /* Checkbox text */\n    font-size: 150%;\n    display: inline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
